@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.rowBackgroundColor {
  background-color: #fbfbfb;
}

.ant-table-thead > tr > th {
  /* font-size: 10px; */
  font-weight: 1000 !important;
  background-color: #238c31 !important;
  color: rgb(255, 255, 255) !important;
}

.site-card-wrapper {
  padding: 30px;
  background: #ffffff;
}

/* tr:hover:not(.ant-table-expanded-row) {
  background: #238c31 !important;
}

td:hover:not(.ant-table-expanded-row) {
  background: #238c31 !important;
} */

tr:hover:not(.ant-table-expanded-row) > td {
  background: #d5d8dc !important;
}

.ant-table-filter-trigger.active {
  color: #ffffff !important;
}

.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0 !important;
}

ant-table-cell ant-table-cell-fix-left ant-table-cell-ellipsis {
  padding: 10px !important;
}

.ant-table-body tr {
  padding: 10px !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 1px solid #238c31 !important;
  /* background-color: #238c31 !important; */
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #238c31 !important;
}

#components-table-demo-resizable-column .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.erpOption {
  line-height: 17px;
  font-size: 14px;
  font-family: "Core Sans GS";
}

#components-table-demo-resizable-column .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
